/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { makeStyles } from '@material-ui/core/styles';

import {
  DialogTypes,
  AddressValidationStatus,
  ResponseStatuses,
  ApiTimeOut,
  TimeOutErrorMessageFromGrand,
  RetryMaxCount,
} from '../../../../constants/dialog.const';
import { SendResendReturnLabel } from './../../../../constants/permissions.const';
import useBurnGiftCards from '../../../../hooks/useBurnGiftCards';
import useHasPermission from './../../../../hooks/useHasPermission';
import { useHistoryPushWithSessionId } from '../../../../hooks/useHistorySessionId';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import useSnacks from '../../../../hooks/useSnacks';
import ADDRESS_VALIDATION_MUTATION from '../../../../mutations/addressValidation.mutation';
import RETURN_CAPTURE_MUTATION from '../../../../mutations/returnCapture.mutation';
import RETURN_LABEL_MUTATION from '../../../../mutations/returnLabel.mutation';
import RETURN_LOCATION_MUTATION from '../../../../mutations/returnLocation.mutation';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import getOrderRoutes from '../../../../routes';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { AthleteContext } from '../../../../store/contexts/athleteContext';
import { ConsumerContext } from '../../../../store/contexts/consumerContext';
import { normalizeAddress } from '../../../../utils/address';
import { getOmoboFlags } from '../../../../utils/orderLine';
import {
  areReasonsMissingFrom,
  isQuantityMissingForLineItems,
  isReturnLabelNotRequired,
  addressHasRequiredFields,
} from '../../../../utils/dialog';
import email from './../../../../utils/email';
import { areLineItemsDigitalGiftCards } from '../../../../utils/order';
import {
  removeNullValuesFromObject,
  removeTypeNameFromObject,
} from './../../../../utils/reactUtils';
import AddressValidator from '../../../shared/addressValidator';
import translations from '../dialog.i18n';
import {
  NextButton,
  BackButton,
  SubmitReturnCaptureButton,
  ResendReturnLabelButton,
} from '../shared/buttons';
import { stepControlSharedStyles } from '../sharedStyles';
import {
  generateReturnCapturePayload,
  generateReturnLocationPayload,
  generateAddressValidationPayload,
} from './requestPayloads/payloads.US';
import { getEmail } from './requestPayloads/utility';
// import { generateAccertifyReturnRequestPayload } from './requestPayloads/accertifyPayLoads';
// import ACCERTIFY_RETURN_REQUEST_MUTATION from '../../../../mutations/accertifyReturn.mutation';

/**
 * Component to handle step actions and form submission based on dialog state for US region
 * Possible states and what's shown:
 *      Steps before the last: Back and Next buttons
 *      Last step, pre-submit: Back and Submit buttons
 */
function StepControl() {
  const classes = useStyles();
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [orderDetail] = useContext(OrderContext);
  const [consumerState] = useContext(ConsumerContext);
  const [, setOrderDetails] = useContext(OrderContext);
  const [isReadyToSubmitReturnOrder, setIsReadyToSubmitReturnOrder] = useState(false);
  const [queryDetailsCounter, setQueryDetailsCounter] = useState(1);
  const [queryDetailsSuccessCounter, setQueryDetailsSuccessCounter] = useState(0);
  const [isOrderDetailsUpdated, setIsOrderDetailsUpdated] = useState(false);
  const [isDispatchReturnLabelComplete, setIsDispatchReturnLabelComplete] = useState(false);
  const [dispatchReturnLabelAttemptCounter, setDispatchReturnLabelAttemptCounter] = useState(0);
  const [dispatchReturnLabelError, setDispatchReturnLabelError] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [addressFromAddressValidation, setAddressFromAddressValidation] = useState('');
  const [addressToValidate, setAddressToValidate] = useState('');
  const { hasPermission } = useHasPermission();
  const { setSlowLoading, setSnack, setError, getLoadingStatus } = useSnacks();
  const [receivingNode, setReceivingNode] = useState('');
  const [standardCarrierAlphaCode, setStandardCarrierAlphaCode] = useState('');
  const [bypassReturnLabelCreation, setBypassReturnLabelCreation] = useState(false);
  const [athleteInfo] = useContext(AthleteContext);
  const setRoute = useHistoryPushWithSessionId();
  const routes = getOrderRoutes();
  const { isBOPIS, isShipToStore } = getOmoboFlags(dialogState.selectedLines);
  const isGiftCardAddress = areLineItemsDigitalGiftCards(dialogState.selectedLines);
  const isStoreOrPickupAddress = (isBOPIS || isShipToStore) && !isGiftCardAddress;

  const {
    prevStep,
    nextStep,
    reset,
    setReturnOrderNumber,
    setShipToAddress,
    setValidateAddress,
    setHasTimedOut,
  } = dialogActions;

  const {
    selectedLines,
    activeStep,
    deliveryMethod,
    dialogType,
    submissionSteps,
    returnOrderNumber,
    address,
    gcShippingAddress,
    validateAddress,
    lock,
    hasTimedOut,
  } = dialogState;

  const {
    ADDRESS_VALIDATION_ERROR,
    CREATE_RETURN_TIME_OUT_ERROR_MESSAGE,
    ERROR,
    PARTIAL_SUCCESS,
    RETURN_CREATION_FAILED,
    RETURN_CREATION_LABEL_FAILED,
    RETURN_ORDER_CREATED,
    RETURN_ORDER_NOT_AVAILABLE,
    RESEND_RETURN_LABEL,
    RETURN_LOCATION_FAILED,
    SUCCESS,
  } = useMemoTranslations(translations);
  const maxAttemptsToDispatchLabel = 2;

  const { findAndBurnGiftCards } = useBurnGiftCards({});

  /**
   * A util function to determine if the submit action is ready
   */
  const shouldSubmissionButtonBeDisabled = () => {
    const geo = orderDetail.omsRegionReference;
    const email = getEmail(dialogState);
    let addressToValidate = { ...address, email };
    if (
      dialogState.activeStep !== 2 &&
      dialogState.deliveryMethod !== 'mail' &&
      dialogState.dialogType !== 'RESEND_RETURN_LABEL'
    ) {
      return !addressHasRequiredFields(geo, addressToValidate, isStoreOrPickupAddress);
    } else if (dialogState.activeStep === 2 && dialogState.returnPaymentOption === 'giftcard') {
      addressToValidate = { ...gcShippingAddress };
      // Email is not required field in gift card payment form
      delete addressToValidate.email;
      return !addressHasRequiredFields(geo, addressToValidate, isStoreOrPickupAddress);
    } else if (dialogState.activeStep === 3 && dialogState.deliveryMethod === 'mail') {
      // Email is not required field in gift card payment form
      delete addressToValidate.email;
      return !addressHasRequiredFields(geo, addressToValidate, isStoreOrPickupAddress);
    } else {
      return false;
    }
  };

  /**
   * Determines whether the next button, which are displayed on non-submission pages,
   * should be disabled or not based on validations.
   */
  const shouldNextButtonBeDisabled = () => {
    const geo = orderDetail.omsRegionReference;
    switch (activeStep) {
      case 0:
        return selectedItemKeys.length === 0;
      case 1:
        return (
          areReasonsMissingFrom(selectedLines, DialogTypes.RETURN) ||
          isQuantityMissingForLineItems(selectedLines, DialogTypes.RETURN)
        );
      case 2:
        let isGifteeEmailAvailable = true;
        if (dialogState.isGiftReturn) {
          if (!email(dialogState.gifteeEmail)) {
            isGifteeEmailAvailable = false;
          }
        }
        // Check address for required fields only if we are in gift card address form
        if (dialogState.returnPaymentOption === 'giftcard') {
          let gcShippingAddressToBeValidated = { ...gcShippingAddress };
          // email is not required in new gift card address form in return step 3
          delete gcShippingAddressToBeValidated.email;
          return (
            !addressHasRequiredFields(
              geo,
              gcShippingAddressToBeValidated,
              isStoreOrPickupAddress
            ) || !isGifteeEmailAvailable
          );
        } else {
          // Disable next button if the email address is not provided for gift return orders
          return !isGifteeEmailAvailable;
        }
      // TODO eslint-disable-next-line no-fallthrough
      default:
        return true;
    }
  };

  /**
   * Updates url to match new return order if return order has been successfully made.
   */
  const updateUrlForNewReturn = () => {
    setRoute(`/order/${returnOrderNumber}/${routes[0]}`);
  };

  const [attemptToDispatchReturnLabel] = useMutation(RETURN_LABEL_MUTATION, {
    onError: (err) => {
      setDispatchReturnLabelAttemptCounter(dispatchReturnLabelAttemptCounter + 1);
      setDispatchReturnLabelError(err);
      setIsDispatchReturnLabelComplete(
        dispatchReturnLabelAttemptCounter >= maxAttemptsToDispatchLabel
      );
    },
    onCompleted: () => {
      setDispatchReturnLabelError(null);
      setIsDispatchReturnLabelComplete(true);
    },
  });

  const [sendReturnLabelNow] = useMutation(RETURN_LABEL_MUTATION, {
    onError: (err) => {
      setError(`${RESEND_RETURN_LABEL} ${ERROR} ${err.message}`);
    },
    onCompleted: () => {
      // todo - do something more appropriate when the call isn't timing out?
      dispatchReset();
      setSnack(`${SUCCESS}`);
    },
  });

  const [doAddressValidation] = useMutation(ADDRESS_VALIDATION_MUTATION, {
    onError: (err) => {
      setError(`${ADDRESS_VALIDATION_ERROR} ${err.message}`);
    },
    onCompleted: (data) => {
      const addressFromResponse = normalizeAddress(data.addressValidator.address);
      const status = data.addressValidator.verificationCode;
      const score = data.addressValidator.score;
      removeNullValuesFromObject(addressFromResponse);
      setVerificationCode(status);
      setAddressFromAddressValidation(addressFromResponse);
      const shouldSubmitOnCurrentStep =
        activeStep !== 2 ||
        (activeStep === 2 && bypassReturnLabelCreation) ||
        (activeStep === 2 && !hasPermission(SendResendReturnLabel, orderDetail.omsRegionReference));
      /*
       * Show address validation component only for
       * a) Addresses that are not in verified status
       * b) Verified address with score less than 95
       * For verified address if the step is return creation step
       * then submit return or move to next step
       */
      if (status === AddressValidationStatus.VERIFIED && score >= 95 && shouldSubmitOnCurrentStep) {
        handleReturnOrderSubmit();
      } else if (status === AddressValidationStatus.VERIFIED && score >= 95 && activeStep === 2) {
        dialogDispatch(nextStep());
      } else {
        dialogDispatch(setValidateAddress(true));
      }
    },
  });

  /**
   * Creates a query used to retrieve the OrderDetails that can be executed at a
   * different time. This is used for the retry calls in the event that the return
   * order has been created, but is not yet retrievable. Hence, we define pollInterval
   * to automatically initiate retry calls after the first call is made.
   */
  const [
    queryOrderDetails,
    { data: queryOrderDetailsData, error: queryOrderDetailsError, stopPolling },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    errorPolicy: 'all', // allows us to receive errors along with partial data
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: 1500,
    onError: () => {
      const isOrderDetail404 =
        queryOrderDetailsError?.message?.includes('404') &&
        /* 
          if the 404 were coming from a nested/stitched query, "path" would include nested 
          data nodes (e.g. ["orderDetail", "shipmentsV2"]), which is why we ensure the path
          only includes "orderDetail"
        */
        queryOrderDetailsError?.path?.toString() === 'orderDetail';
      /*
        If the error we receive is _not_ a 404 and we have received partial data
        then we have received order detail data for the newly created return order,
        and we should stop polling, update order details in state, and direct the user
        to the order details page for the new return order.
      */
      if (!isOrderDetail404 && queryOrderDetailsData?.orderDetail) {
        stopPolling();
        setOrderDetails(queryOrderDetailsData?.orderDetail);
        setIsOrderDetailsUpdated(true);
        if (
          !hasPermission(SendResendReturnLabel, orderDetail.omsRegionReference) ||
          bypassReturnLabelCreation
        ) {
          dispatchReset();
          setSnack(RETURN_ORDER_CREATED);
          updateUrlForNewReturn();
        }
      }
      /*
        If the error we receive _is_ a 404 and/or we do not have partial data,
        then we allow polling to continue, but we tally our polls so that we may 
        cut our losses once 10 requests have been made and failed.
      */
      if (queryDetailsCounter === 10) {
        setError(RETURN_ORDER_NOT_AVAILABLE);
        dispatchReset();
        stopPolling();
      } else {
        setQueryDetailsCounter(queryDetailsCounter + 1);
      }
    },
    onCompleted: () => {
      const setReturnOrderSuccess = () => {
        // Set Order details on successful return creation
        setOrderDetails(queryOrderDetailsData.orderDetail);
        setIsOrderDetailsUpdated(true);
        if (
          !hasPermission(SendResendReturnLabel, orderDetail.omsRegionReference) ||
          bypassReturnLabelCreation
        ) {
          dispatchReset();
          setSnack(RETURN_ORDER_CREATED);
          updateUrlForNewReturn();
        }
      };
      /* If call is successful without errors and orderheaderkey is available, 
          we stop making retry calls */
      if (queryOrderDetailsData?.orderDetail?.orderHeaderKey) {
        stopPolling();
        setReturnOrderSuccess();
        return;
      }
      if (queryDetailsSuccessCounter >= RetryMaxCount) {
        stopPolling();
        setReturnOrderSuccess();
      } else {
        setQueryDetailsSuccessCounter(queryDetailsSuccessCounter + 1);
      }
    },
  });

  // Generate the function to execute the return location mutation
  const [submitReturnLocation] = useMutation(RETURN_LOCATION_MUTATION, {
    onError: (err) => {
      setError(RETURN_LOCATION_FAILED);
    },
    onCompleted: (response) => {
      setReceivingNode(response.returnLocation.receivingNode);
      setStandardCarrierAlphaCode(response.returnLocation.standardCarrierAlphaCode);
      // Update the shipTo field using the value we get return location API
      const { address, recipient } = response.returnLocation.shipTo;
      const shipTo = {
        address: {
          ...removeTypeNameFromObject(address),
        },
        company: recipient.company,
      };
      dialogDispatch(setShipToAddress(shipTo));
      setIsReadyToSubmitReturnOrder(true);
    },
  });

  // const [submitAccertifyRequest] = useMutation(ACCERTIFY_RETURN_REQUEST_MUTATION, {
  //   onError: (err) => {
  //     console.error('Accertify Return Request Error', err);
  //   },
  //   onCompleted: (response) => {
  //     console.log('Accertify Return Request Response', response);
  //   },
  // });

  /*
  Based on the return step and shipping/ billing country we either perform or skip
  address validation
  Skip Address Validation in the below scenarios 
   a) In return process in step1
   b) In step2, if return payment option is not gift card
   Perform address validation in all other scenarios
  */
  const isAddressValidationRequired = () => {
    if (activeStep < 2) return false;
    if ((activeStep === 2 && dialogState.returnPaymentOption === 'giftcard') || activeStep === 3) {
      return true;
    }
    return false;
  };

  // Generate the function to execute the return captures mutation.
  const [submitReturnOrder, { loading: returnLoading }] = useMutation(RETURN_CAPTURE_MUTATION, {
    onError: (err) => {
      // Logging the error message so we do not lose track of it.
      console.error('Return Capture Error', err);
      if (err.message === TimeOutErrorMessageFromGrand) {
        setError(CREATE_RETURN_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else {
        // If the return order submission fails, we want to display a readable error and go back to the order screen.
        setError(RETURN_CREATION_FAILED);
      }
      dispatchReset();
    },
    onCompleted: async (response) => {
      const { createReturn } = response;
      if ((createReturn.status = ResponseStatuses.COMPLETED && createReturn.response)) {
        if (createReturn.response.returnOrderNumber) {
          dialogDispatch(setReturnOrderNumber(createReturn.response.returnOrderNumber));
          const burnFailures = await findAndBurnGiftCards();
          /* 
            if gift card(s) were returned and burning balance(s) succeeded, then queryOrderDetails, 
            otherwise reset/close the dialog so burnGiftCards failure data will be visible on the screen
          */
          if (!Object.keys(burnFailures).length > 0) {
            queryOrderDetails({
              variables: {
                orderNumber: createReturn.response.returnOrderNumber,
                isFollowUp: true,
              },
            });
          } else {
            dispatchReset();
          }
        }
      } else if (
        createReturn.status === ResponseStatuses.IN_PROGRESS ||
        createReturn.status === ResponseStatuses.PENDING
      ) {
        setError(CREATE_RETURN_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else if (createReturn.error) {
        const errorMessageOnJobCompletion = `${createReturn.error.httpStatus}: ${createReturn.error.message}`;
        setError(errorMessageOnJobCompletion);
        dispatchReset();
      }
    },
  });

  /**
   * When the selectedLines is updated, determine if all of the reason codes for the return items
   * does not require return label. Set the byPassReturnLabelCreation flag to true if they are and
   * false if they are not.
   */
  useEffect(() => {
    setBypassReturnLabelCreation(
      isReturnLabelNotRequired(dialogState.selectedLines, orderDetail.omsRegionReference)
    );
  }, [dialogState.selectedLines]);

  // Need to wait for shipTo address to be populated in state before we can request a return
  useEffect(() => {
    if (isReadyToSubmitReturnOrder) {
      // Loop through the selected lines for return and make accertify call for each order line separately
      // commenting out the accertify call as requested by the team temporarily
      // Object.keys(dialogState.selectedLines).forEach((key) => {
      //   const selectedLine = dialogState.selectedLines[key];
      //   const accertifyRequestPayLoad = generateAccertifyReturnRequestPayload(
      //     dialogState,
      //     selectedLine,
      //     orderDetail,
      //     athleteInfo
      //   );
      //   // For phase 1 as we are not going to take any action on the accertify response we
      //   // just make parallel calls and leave it. in phase 2 we will wait for the response before proceeding
      //   submitAccertifyRequest({ variables: { input: accertifyRequestPayLoad } });
      // });
      const returnCapturesInput = generateReturnCapturePayload(
        dialogState,
        consumerState,
        orderDetail,
        hasPermission(SendResendReturnLabel, orderDetail.omsRegionReference),
        receivingNode,
        standardCarrierAlphaCode,
        athleteInfo
      );
      submitReturnOrder({
        variables: {
          input: returnCapturesInput,
          region: orderDetail.omsRegionReference,
          timeout: ApiTimeOut,
        },
      });
    }
  }, [isReadyToSubmitReturnOrder]);

  /**
   * This useEffect attempts to dispatch the return label.  It is triggered at first when
   * the order details are updated after successfully creating a return order, and then
   * subsequently each time that dispatching the return label fails but has not yet
   * exceeded the maximum number of attempts.
   */
  useEffect(() => {
    if (
      hasPermission(SendResendReturnLabel, orderDetail.omsRegionReference) &&
      !bypassReturnLabelCreation
    ) {
      if (isOrderDetailsUpdated && !isDispatchReturnLabelComplete) {
        const returnLabelInput = {
          returnOrderNumber: dialogState.returnOrderNumber,
          dispatchType: String(deliveryMethod).toUpperCase(),
        };
        attemptToDispatchReturnLabel({
          variables: {
            input: returnLabelInput,
          },
        });
      }
    } else if (isOrderDetailsUpdated) {
      // Since there is no return label, set this to true to trigger the reset and confirmation snack.
      setIsDispatchReturnLabelComplete(true);
    } else if (returnOrderNumber && lock) {
      /* we land here when athlete comes from swoosh desk, and a return order is created but
       the return label is not required either because of
       a) Athlete doesn't have permission to send return label
       b) Reason code selected doesn't warrant return label
       */
      dispatchReset();
      setSnack(RETURN_ORDER_CREATED);
      updateUrlForNewReturn();
    }
    return () => {};
  }, [
    isOrderDetailsUpdated,
    dispatchReturnLabelAttemptCounter,
    bypassReturnLabelCreation,
    returnOrderNumber,
  ]);

  /**
   * This useEffect resets the dialog and dispatches a confirmation snack indicating either complete
   * success, or partial success if the dispatching of the return label failed.  It is triggered when
   * the call to dispatch the return label is complete, or if there is no return label to dispatch
   * then it is triggered right after the order details are updated.
   */
  useEffect(() => {
    if (isDispatchReturnLabelComplete & (activeStep !== 2)) {
      let isDispatchReturnLabelFailed =
        hasPermission(SendResendReturnLabel, orderDetail.omsRegionReference) &&
        dispatchReturnLabelError &&
        dispatchReturnLabelAttemptCounter > 0;
      dispatchReset();
      if (isDispatchReturnLabelFailed) {
        setSnack(
          `${PARTIAL_SUCCESS} ${RETURN_CREATION_LABEL_FAILED} ${dispatchReturnLabelError.message}`,
          'yellow'
        );
      } else {
        updateUrlForNewReturn();
        setSnack(RETURN_ORDER_CREATED);
      }
    }
  }, [isDispatchReturnLabelComplete]);

  const dispatchReset = () => {
    dialogDispatch(reset());
  };

  /**
   * Determines if we are on a submission step, but also whether we are submitting the return
   * order at step 2 or 3, which is dependent on if the user wants a return label sent.
   */
  const determineIfSubmissionStep = () => {
    if (submissionSteps.includes(activeStep)) {
      if (dialogType === DialogTypes.RESEND_RETURN_LABEL) {
        return true;
      } else if (activeStep === 2 && bypassReturnLabelCreation) {
        return true;
      } else {
        return (
          (activeStep === 2 &&
            !hasPermission(SendResendReturnLabel, orderDetail.omsRegionReference)) ||
          activeStep === 3
        );
      }
    }
    return false;
  };

  const isSubmissionStep = determineIfSubmissionStep();
  const selectedItemKeys = Object.keys(selectedLines);
  const isSubmissionLoading = getLoadingStatus() || returnLoading;

  /**
   * Function that calls address validation api
   */
  const handleAddressValidation = () => {
    const { address, gcShippingAddress, activeStep } = dialogState;
    const addressToBeSentToAPI =
      // If this is step 2 or the selected lines are all digital gift cards, use gift card address
      activeStep === 2 || areLineItemsDigitalGiftCards(selectedLines) ? gcShippingAddress : address;
    setAddressToValidate(addressToBeSentToAPI);
    const addressValidationPayload = generateAddressValidationPayload(
      addressToBeSentToAPI,
      orderDetail.locale
    );

    doAddressValidation({
      variables: {
        input: addressValidationPayload,
        guid: uuidv4(),
      },
    });
  };

  // Object that contains address entered in the form and suggested address from service
  const suggestedAndEnteredAddress = {
    suggestedAddress: removeTypeNameFromObject(addressFromAddressValidation),
    addressEntered: addressToValidate,
  };

  /**
   * Handles the submission of the createReturn mutation.
   */
  function handleReturnOrderSubmit() {
    const returnLocationInput = generateReturnLocationPayload(
      dialogState,
      consumerState,
      orderDetail
    );

    setSlowLoading();

    submitReturnLocation({ variables: { input: returnLocationInput } });

    /*
     * We need to wait for state to update - see useEffect for the submitReturnOrder call
     * which watches for dialogueState.shipTo address to update
     */
  }

  const handleNext = () => {
    if (isAddressValidationRequired()) {
      return handleAddressValidation();
    }
    dialogDispatch(nextStep());
  };

  /**
   * This function will determine whether to call address validation or directly
   * submit returns with out address validation
   */
  const determineReturnSubmissionPath = () => {
    if (isAddressValidationRequired()) {
      return handleAddressValidation();
    }
    return handleReturnOrderSubmit();
  };

  const handleResendReturnLabelClick = () => {
    setSlowLoading();
    sendReturnLabelNow({
      variables: {
        input: {
          returnOrderNumber: orderDetail.orderNumber,
          dispatchType: String(deliveryMethod).toUpperCase(),
        },
      },
    });
  };

  // action buttons, depending on dialog type
  const getSubmissionButton = () => {
    switch (dialogType) {
      case DialogTypes.RETURN: {
        return (
          <SubmitReturnCaptureButton
            disabled={shouldSubmissionButtonBeDisabled() || isSubmissionLoading || hasTimedOut}
            onClick={determineReturnSubmissionPath}
          />
        );
      }
      case DialogTypes.RESEND_RETURN_LABEL: {
        return (
          <ResendReturnLabelButton
            disabled={shouldSubmissionButtonBeDisabled()}
            onClick={handleResendReturnLabelClick}
          />
        );
      }
      default:
        return;
    }
  };

  if (!isSubmissionStep) {
    return (
      <>
        <div className={classes.actionsContainer}>
          <BackButton disabled={activeStep === 0} onClick={() => dialogDispatch(prevStep())} />
          <NextButton disabled={shouldNextButtonBeDisabled()} onClick={handleNext} />
        </div>
        {/* Display address validator component based on address validator api response */
        validateAddress && (
          <AddressValidator
            address={suggestedAndEnteredAddress}
            status={verificationCode}
            createReturnOrder={handleReturnOrderSubmit}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <div className={classes.actionsContainer}>
          <BackButton disabled={activeStep === 0} onClick={() => dialogDispatch(prevStep())} />
          {getSubmissionButton()}
        </div>
        {/* Display address validator component based on address validator api response */
        validateAddress && (
          <AddressValidator
            address={suggestedAndEnteredAddress}
            status={verificationCode}
            createReturnOrder={handleReturnOrderSubmit}
            endOnActiveStep2={bypassReturnLabelCreation}
          />
        )}
      </>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  ...stepControlSharedStyles,
}));

export default withRouter(StepControl);

import sharedTranslations from './createReturn/shared.i18n';

const translations = {
  BACK: sharedTranslations.BACK,
  CREATE_RETURN: sharedTranslations.CREATE_RETURN,
  NEXT: sharedTranslations.NEXT,
  ERROR: sharedTranslations.ERROR,
  PARTIAL_SUCCESS: sharedTranslations.PARTIAL_SUCCESS,
  SUCCESS: sharedTranslations.SUCCESS,
  ADD_COMMENT: {
    description: 'Title of Add Comment page',
    primaryValue: 'Add Comment',
    stringKey: 'addComment',
  },
  ARIA_RETURN_STEPS: {
    description: 'aria label for return steps',
    primaryValue: 'return steps',
    stringKey: 'ariaReturnSteps',
  },
  ARIA_MODIFY_PRICE_STEPS: {
    description: 'aria label for modify price steps',
    primaryValue: 'modify price steps',
    stringKey: 'ariaModifyPriceSteps',
  },
  ARIA_CANCEL_STEPS: {
    description: 'aria label for cancel steps',
    primaryValue: 'cancel steps',
    stringKey: 'ariaCancelSteps',
  },
  ARIA_INSPECTION_STEPS: {
    description: 'aria label for inspection steps',
    primaryValue: 'inspection steps',
    stringKey: 'ariaInspectionSteps',
  },
  ARIA_RESEND_STEPS: {
    description: 'aria label for resend steps',
    primaryValue: 'resend steps',
    stringKey: 'ariaResendSteps',
  },
  ARIA_DIALOG_HEADER: {
    description: 'aria label for dialog header',
    primaryValue: 'dialog header',
    stringKey: 'ariaDialogHeader',
  },
  ADD_ITEM_DETAILS: {
    description: 'label on the Create Return Dialog for adding item details',
    primaryValue: 'Add Item Details',
    stringKey: 'addItemDetails',
  },
  ADD_RETURN_LABEL_DETAILS: {
    description: 'label on the Create Return Dialog for adding return label details',
    primaryValue: 'Add Return Label Details',
    stringKey: 'addReturnLabelDetails',
  },
  ADD_RETURNEE_DETAILS: {
    description: 'label on the Create Return Dialog for adding returnee details',
    primaryValue: 'Add Returnee Details',
    stringKey: 'addReturneeDetails',
  },
  ADDRESS_VALIDATION_ERROR: {
    description: 'Error when address validation api fails',
    primaryValue: 'Address Validation error',
    stringKey: 'addressValidationError',
  },
  BURN_GIFTCARD_ERROR: {
    stringKey: 'burnGiftCardFailure',
    primaryValue: 'Return created, but removing the gift card balance failed.',
    description:
      'Error message to display when gift card is successfully returned, but balance is not successfully burned/cleared.',
  },
  GIFTCARD_NUMBER: {
    stringKey: 'giftCardNumber',
    primaryValue: 'Gift Card Number',
    description: 'label to precede displaying agift card number',
  },
  DISCOUNT_SHIPPING: {
    description: 'label on the Discount Shipping Dialog',
    primaryValue: 'Discount Shipping',
    stringKey: 'discountShipping',
  },
  EXIT: {
    description: 'label on the Create Return Dialog for "Exit"',
    primaryValue: 'Exit',
    stringKey: 'exit',
  },
  CREATE_A_RETURN: {
    description: 'label on the Create Return Dialog for "Create a Return"',
    primaryValue: 'Create a Return',
    stringKey: 'createAReturn',
  },
  SELECT_ITEMS_TO_RETURN: {
    description: 'label on the Create Return Dialog for "Select Items to Return"',
    primaryValue: 'Select Items to Return',
    stringKey: 'selectItemsToReturn',
  },
  SELECT_ITEMS_TO_MODIFY_PRICE: {
    description: 'label on the Create Return Dialog for "Select Items to Modify Price"',
    primaryValue: 'Select Items to Modify Price',
    stringKey: 'selectItemsToModifyPrice',
  },
  CANCEL_ITEMS: {
    description: 'label on the Cancel Items Dialog for "Cancel Items"',
    primaryValue: 'Cancel Items',
    stringKey: 'cancelItems',
  },
  SELECT_ITEMS_TO_CANCEL: {
    description: 'label on the Cancel Items Dialog for "Select Items to Cancel"',
    primaryValue: 'Select Items to Cancel',
    stringKey: 'selectItemsToCancel',
  },
  ARIA_EXCHANGE_STEPS: {
    description: 'aria label for exchange steps',
    primaryValue: 'Exchange Steps',
    stringKey: 'ariaExchangeSteps',
  },
  CREATE_EXCHANGE: {
    description: 'label on the Create Exchange Dialog for "Create Exchange"',
    primaryValue: 'Create Exchange',
    stringKey: 'createExchange',
  },
  INSPECT_RETURN: {
    stringKey: 'inspectReturn',
    primaryValue: 'Inspect Return',
    description: 'label on the Inspect Return Dialog for "Inspect a Return"',
  },
  RESEND_RETURN_LABEL: {
    stringKey: 'resendReturnLabel',
    primaryValue: 'Resend Return Label',
    description: 'title on the Resend-Return-Label Dialog',
  },
  MODIFY_PRICE: {
    stringKey: 'modify',
    primaryValue: 'Modify Price',
    description: 'title on the modify price Dialog',
  },
  MODIFY_ITEM_PRICE: {
    description: 'Modify Price dialog header label',
    primaryValue: 'Modify Item Price',
    stringKey: 'modifyItemPrice',
  },
  RETURN_CREATION_FAILED: {
    stringKey: 'returnCreationFailed',
    primaryValue: 'Return order creation failed. Please try again.',
    description: 'Error message to display when a return creation fails.',
  },
  RETURN_CREATION_LABEL_FAILED: {
    stringKey: 'returnCreationLabelFailed',
    primaryValue: 'The Return was created, but Return Label was unsuccessful.',
    description:
      'Partial Success message to display when a return creation succeeds but dispatching the return label fails',
  },
  RETURN_LOCATION_FAILED: {
    stringKey: 'returnLocationFailed',
    primaryValue: 'Return location failed. Please try again.',
    description: 'Error message to display when return location fails.',
  },
  RETURN_ORDER_NOT_AVAILABLE: {
    stringKey: 'returnOrderNotAvailable',
    primaryValue: 'Return order not available yet. Please refresh the page.',
    description: 'Error message to display when the return order is not available yet.',
  },
  RETURN_ORDER_CREATED: {
    stringKey: 'returnOrderCreated',
    primaryValue: 'Return order created successfully.',
    description: 'Message to display when the return order was created successfully',
  },
  SELECT_ITEMS_TO_INSPECT: {
    description: 'label on the Inspect returns Dialog for "Select Items to Inspect"',
    primaryValue: 'Select Items to Inspect',
    stringKey: 'selectItemsToInspect',
  },
  SELECT_REASON_FOR_DISCOUNT: {
    description: 'label on the Discount Shipping Dialog',
    primaryValue: 'Select Reason for Discount',
    stringKey: 'selectReasonForDiscount',
  },
  CREATE_RETURN_TIME_OUT_ERROR_MESSAGE: {
    stringKey: 'createReturnTimeoutErrorMessage',
    primaryValue:
      'Return is still pending. Please return to SwooshDesk and check back after some time',
    description: 'Error message when create return call times out',
  },
  ACTION_COMPLETE: {
    stringKey: 'actionComplete',
    primaryValue: 'Action Complete',
    description: 'label for success dialog for swooshdesk integration',
  },
  CLOSE_TAB: {
    stringKey: 'closeTab',
    primaryValue: 'Please close the tab.',
    description: 'Message in dialog for swooshdesk integration.',
  },
  RETURN_ORDER: {
    stringKey: 'returnOrder',
    primaryValue: 'Return Order',
    description: 'Display label for presenting new order number on end of swooshdesk flow',
  },
  PENDING_MOD_MESSAGE: {
    description: 'Message to display when one or more items in the order are pending modification',
    primaryValue:
      'is pending for one of the items on this order. Please wait until it is complete to make further modifications.',
    stringKey: 'pendingModMessage',
  },
  PICKUP_ADDRESS: {
    description: 'label for step 3 on Korea return flow',
    primaryValue: 'Pickup Address',
    stringKey: 'pickupAddress',
  },
  PUT_FUNDS_ON_HOLD: {
    description: 'message instructing athlete to put gift card funds on hold',
    primaryValue: 'Please put the Gift Card funds on hold.',
    stringKey: 'putFundsOnHold',
  },
  VIEW_CART: {
    stringKey: 'viewCart',
    primaryValue: 'View Cart',
    description: 'Text for View Cart',
  },
  SELECT_ITEMS: {
    stringKey: 'selectItems',
    primaryValue: 'Select Items',
    description: 'label for select item step in exchange flow flow',
  },
  CHOOSE_NEW: {
    stringKey: 'chooseNew',
    primaryValue: 'Choose New',
    description: 'label for selecting new items step in exchange flow',
  },
  REVIEW_ORDER: {
    stringKey: 'reviewOrder',
    primaryValue: 'Review Order',
    description: 'label for reviewing order step in exchange flow',
  },
  RETURN_EXCEPTION_HANDLING: {
    description: 'Return dialog step 3 name for warehouse employee',
    primaryValue: 'Return Exception Handling',
    stringKey: 'returnExceptionHandling',
  },
  PICKUP_OR_NONPICKUP_LABEL: {
    description: 'Return dialog step 3 name for non-warehouse employee',
    primaryValue: 'Pickup or Non-Pickup',
    stringKey: 'pickupOrNonpickupLabel',
  },
  ORDER_OLDER_THAN_90_DAYS: {
    description:
      'Error message displayed when the order is older than 90 days and athlete tries to create return',
    primaryValue:
      'The order is outside the return policy window. No returns except Flawed are allowed.',
    stringKey: 'orderOlderThan90Days',
  },
};

export default translations;

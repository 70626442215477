import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import StyleLink from '../../content/shared/itemLink';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import React, { useContext, useEffect } from 'react';
import { ReasonCodeTypes, ReasonCodeAppId } from '../../../../constants/dialog.const';
import REASON_CODES_QUERY from '../../../../queries/reasonCodes.query';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { I18nContext } from '../../../../store/contexts/i18Context';
import { OrderContext } from '../../../../store/contexts/orderContext';
import AthleteContext from '../../../../store/contexts/athleteContext';
import { AssetsContext } from '../../../../store/contexts/assetsContext';
import { getProductImageFromOrderLine } from '../../../../utils/product';
import { getReturnableQuantity } from '../../../../utils/order';
import Warning from '../../../shared/warning';
import { step2SharedStyles } from '../sharedStyles';
import translations from './step2.i18n';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import { setReturnCaptureDetails } from './../../../../utils/browserStorage';
import { appropriateLanguage } from './../../../../utils/language';
import { useOktaAuth } from '@okta/okta-react';
import { L1AthleteADGroups } from '../../../../constants/permissions.const';
import { isOrderOlderThan } from '../createReturn/requestPayloads/utility';
/**
 * This component is step 2 of the Create Return flow
 *
 */
const Step2 = () => {
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const [athleteInfo] = useContext(AthleteContext);
  const [assets] = useContext(AssetsContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [i18State] = useContext(I18nContext);
  const { authState } = useOktaAuth();

  const athleteADGroups = authState?.accessToken?.claims?.groups;
  const L1Athlete = athleteADGroups.includes(L1AthleteADGroups[orderDetail.omsRegionReference]);
  const orderOlderThan90 = isOrderOlderThan(orderDetail, 90);
  const FLAWED_REASON_CODE = '2';

  const { setQuantityToReturn, setReturnReason } = dialogActions;
  const {
    ONLY_1_AVAILABLE,
    REASON_FOR_RETURN,
    SIZE,
    SELECT_QUANTITY,
    QUANTITY,
    REASON_CODES_LOADING,
    ARIA_RETURN_REASON,
  } = mapValues(translations, i18nString);
  const { selectedLines } = dialogState;

  const classes = useStyles();
  // pull the currency code from the details, for formatting purposes
  const { currency } = orderDetail;
  const { data: reasonCodeData, loading: reasonCodeLoading, error: reasonCodeError } = useQuery(
    REASON_CODES_QUERY,
    {
      variables: {
        appId: ReasonCodeAppId,
        omsRegionReference: orderDetail.omsRegionReference,
        type: ReasonCodeTypes.RETURN,
        language: appropriateLanguage(i18State.language),
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  // This use effect sets the details needed for return capture audit in local storage
  useEffect(() => {
    setReturnCaptureDetails({
      salesOrderNumber: orderDetail.orderNumber,
      // this is partial name remember it
      salesOrderAssistingAgentReference: orderDetail.assistingAgentReference,
      customerProfileReference: orderDetail.customerProfileReference,
      salesOrderTotalAmount: orderDetail.totalAmount,
      athleteName: athleteInfo.name,
    });
  }, []);

  /*
  Set the returnable quantity of a line item to 1 upon entering step 2
  for the first time if the total quantity available for return is 1. 
  */
  useEffect(() => {
    Object.values(selectedLines).forEach((selectedLine) => {
      if (!Boolean(selectedLine.quantityToReturn)) {
        const orderStatuses = Array.isArray(selectedLine.statuses) ? selectedLine.statuses : [];
        if (getReturnableQuantity(orderStatuses) === 1) {
          dialogDispatch(setQuantityToReturn(selectedLine, 1));
        }
      }
    });
  }, []);

  const getFilteredReasonCodes = (reasons) => {
    if (L1Athlete && orderOlderThan90) {
      return reasons.filter((reason) => reason.code === FLAWED_REASON_CODE);
    }
    return reasons;
  };

  return (
    <>
      {Object.values(selectedLines).map((line, i) => {
        const orderStatuses = Array.isArray(line.statuses) ? line.statuses : [];
        const quantityArray = Array.from(
          { length: getReturnableQuantity(orderStatuses) },
          (v, k) => k + 1
        );
        const quantityToReturn = line.quantityToReturn;
        // -1 is the code for the default, unselectable reason
        const reason = line.returnReason || { code: '-1' };
        return (
          <Card key={i} className={classes.itemDetailsCard} data-testid='return-step2-card'>
            <CardMedia
              className={classes.productThumb}
              image={getProductImageFromOrderLine(line, assets)}
              title={line.itemDescription}
            />
            <div className={classes.itemDetails}>
              <CardContent className={classes.itemDetailsContent}>
                <Typography variant='body1'>
                  <StyleLink orderDetail={orderDetail} line={line} />
                </Typography>
                <Typography variant='body1'>{line.item && line.item.itemDescription}</Typography>
                <Typography variant='body1'>{line.colorDescription}</Typography>
                <Typography variant='body1'>
                  {SIZE} {line.displaySize}
                </Typography>
                <Typography variant='body1'>
                  <FormattedCurrency
                    amount={line.linePriceInformation.unitPrice}
                    currency={currency}
                  />
                </Typography>
                <FormControl className={classes.quantitySelect}>
                  <Tooltip
                    title={ONLY_1_AVAILABLE}
                    placement='right'
                    enterDelay={200}
                    leaveDelay={100}
                    classes={
                      quantityArray.length === 1
                        ? { tooltip: classes.tooltip }
                        : { tooltip: classes.noDisplay }
                    }>
                    <>
                      {quantityArray.length === 1 && (
                        <Typography
                          variant='body1'
                          id='chooseQuantity'
                          component='span'
                          className={classes.quantityLabel}>
                          {QUANTITY}
                          {':'}
                        </Typography>
                      )}
                      <Select
                        value={quantityToReturn || -1}
                        name={line.lineNumber.toString()}
                        aria-labelledby='chooseQuantity'
                        labelId='chooseQuantity'
                        onChange={(event) =>
                          dialogDispatch(setQuantityToReturn(line, event.target.value))
                        }
                        disabled={quantityArray.length === 1}
                        data-testid={`select-returnable-quantity-${i}`}>
                        <MenuItem value='-1' disabled>
                          {SELECT_QUANTITY}
                        </MenuItem>
                        {quantityArray.map((quantity, k) => (
                          <MenuItem
                            key={k}
                            value={quantity}
                            name={quantity}
                            data-testid={`returnable-quantity-${quantity}`}>
                            {quantity}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  </Tooltip>
                </FormControl>
                {reasonCodeLoading && (
                  <CircularProgress aria-label={REASON_CODES_LOADING} className={classes.loading} />
                )}
                {reasonCodeError && <Warning message={reasonCodeError.message} />}
                {reasonCodeData && reasonCodeData.reasonCodesV2.reasons ? (
                  <FormControl className={classes.reasonCodeSelect}>
                    <Select
                      value={reason.code}
                      name={line.lineNumber.toString()}
                      data-testid={`select-return-reason-${i}`}
                      aria-label={ARIA_RETURN_REASON}
                      aria-labelledby='chooseReason'
                      labelId='chooseReason'
                      onChange={(event) => {
                        /**
                         * save both the reason code and the reason
                         * description
                         */
                        const selectedReason = reasonCodeData.reasonCodesV2.reasons.find(
                          (reason) => reason.code === event.target.value
                        );
                        dialogDispatch(
                          setReturnReason(line, {
                            id: selectedReason.id,
                            code: selectedReason.code,
                            text: selectedReason.description,
                          })
                        );
                      }}>
                      <MenuItem
                        id='chooseReason'
                        className={classes.reasonCodeSelect}
                        value='-1'
                        disabled>
                        {REASON_FOR_RETURN}
                      </MenuItem>
                      {getFilteredReasonCodes(reasonCodeData.reasonCodesV2.reasons).map(
                        (reason, j) => (
                          <MenuItem
                            key={j}
                            value={reason.code}
                            name={reason.fault}
                            data-testid={`return-reason-${j}`}>
                            {reason.description}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                ) : null}
              </CardContent>
            </div>
          </Card>
        );
      })}
    </>
  );
};

export default Step2;

const useStyles = makeStyles((theme) => ({
  ...step2SharedStyles(theme),
}));
